<template>
  <Breadcrumb :home="breadcrumb.home" :model="breadcrumb.items" style="padding-top:0px;background: none;border: none;"/>
  <div class="card center">
    <div class="form">
      <div class="formItem">
        <label>首页地图缩放级别:</label>
        <div>
          <InputNumber v-model="map['dashboard.map.zoom_level']"
                       :class="{'p-invalid':(v$.map['dashboard.map.zoom_level'].$invalid && submitted)}"></InputNumber>
        </div>
      </div>
      <div class="formItem">
        <label>首页地图中心点:</label>
        <div>
          <InputText v-model="map['dashboard.map.center']"
                     :class="{'p-invalid':(v$.map['dashboard.map.center'].$invalid && submitted)}"></InputText>
        </div>
      </div>
      <div class="p-d-flex p-justify-end ">
        <Button v-permission="['1010','sysconfig','bc']" @click="update">保存</Button>
      </div>
    </div>
  </div>
</template>

<script>
import { useVuelidate } from "@vuelidate/core";
import { required } from "@vuelidate/validators";

export default {
  name: "sysconfig",
  data() {
    return {
      map: {
        "dashboard.map.zoom_level": null,
        "dashboard.map.center": null,
      },
      submitted: false,
      breadcrumb: {
        home: { label: "系统管理", to: "#" },
        items: [{ label: "系统配置", to: "#" }],
      },

    };
  },
  setup: () => ({
    v$: useVuelidate(),
  }),
  validations() {
    return {
      map: {
        "dashboard.map.zoom_level": { required },
        "dashboard.map.center": { required },
      },
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.$axios.get("/conf/list").then((res) => {
        res.data.forEach((item) => {
          this.map[item.code] = item.value;
        });
      });
    },
    update() {
      this.submitted = true;
      if (this.v$.$invalid) {
        return;
      }
      this.$axios.post("/conf/addOrUpdate", this.map).then((res) => {
        this.$toast.add({
          severity: "success",
          summary: "保存成功！",
          life: 3000,
        });
      });
    },
  },
};
</script>

<style scoped>
.center {
  height: calc(100vh - 86px - 2rem);
  justify-content: center;
  align-items: center;
  display: flex;
}

.form {
  /*padding-top: 50px;*/
  text-align: right;
}

.formItem {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.formItem label {
  display: inline-block;
  text-align: right;
  margin-right: 10px;
  flex: 1;
}
</style>
